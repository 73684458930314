import * as React from 'react'
import { getCookie, setCookie } from '../../utils'
import { generateEmailUUID } from './utils'

import { useState } from 'react'

const { useEffect } = React

interface GeoLocationData {
  city?: string
  country?: string
  flag?: string
  region?: string
  countryRegion?: string
  latitude?: string
  longitude?: string
}
interface UserContextValue {
  initialize: (apiKey: string, options: any) => void
  openSession: () => void
  automaticallyShowInAppMessages: () => void
  getUser: () => any
  changeUser: (externalId: string) => void
  isLoaded: boolean
  logCustomEvent: (eventName: string, eventData: any) => void
  getGeoLocation: () => Promise<GeoLocationData>
}

const UserContext = React.createContext<UserContextValue | undefined>(undefined)

export const UserConsumer = UserContext.Consumer

export const useUser = () => {
  const ctx = React.useContext(UserContext)
  if (!ctx) throw new Error('useUserContext must be used within a UserProvider')
  return ctx
}

interface UserProviderProps {
  children: React.ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [brazeExports, setBrazeExports] = useState<any>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const enableLogging = process.env.NEXT_PUBLIC_ENABLE_BRAZE_LOGGING === 'true'

  // Move the Braze initialization into a separate useEffect that runs after exports are loaded
  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('../../../src/utils/braze-exports')
        .then((exports) => {
          setBrazeExports(exports)
          setIsLoaded(true)
        })
        .catch((error) => {
          console.error('Failed to load Braze exports:', error)
        })
    }
  }, [])

  // Only initialize Braze after exports are loaded
  useEffect(() => {
    if (isLoaded && brazeExports) {
      brazeExports.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY, {
        baseUrl: 'sdk.iad-07.braze.com',
        allowUserSuppliedJavascript: true,
        enableLogging: enableLogging,
      })

      brazeExports.automaticallyShowInAppMessages()

      const attntvMstoreEmail = getCookie('attntv_mstore_email')
      const attntvMstorePhone = getCookie('attntv_mstore_phone')

      const user = brazeExports.getUser()
      console.log('The user ID is:', user?.getUserId())

      const cookiesChecked = getCookie('ATTNTV_COOKIES_CHECKED')
      // console.log('cookiesChecked:', cookiesChecked)

      if (!cookiesChecked && attntvMstoreEmail) {
        ;(async () => {
          const formattedEmail = attntvMstoreEmail.includes(':')
            ? attntvMstoreEmail.split(':')[0]
            : attntvMstoreEmail
          const hashedEmail = await generateEmailUUID(formattedEmail)
          brazeExports.changeUser(hashedEmail)
          const updatedUserId = brazeExports.getUser()?.getUserId()
          console.log('The updated user is:', updatedUserId)

          // set the phone number if it exists
          if (attntvMstorePhone) {
            const formattedPhoneNumber = attntvMstorePhone.includes(':')
              ? attntvMstorePhone.split(':')[0]
              : attntvMstorePhone.startsWith('+1')
              ? attntvMstorePhone
              : `+1${attntvMstorePhone}`
            const setPhoneNumber = brazeExports
              .getUser()
              ?.setPhoneNumber(formattedPhoneNumber)
            console.log(
              'Attempted to set Phone number in Braze:',
              setPhoneNumber,
              formattedPhoneNumber,
            )
          }

          setCookie('ATTNTV_COOKIES_CHECKED', 'true', { expires: 365 })
        })()
      }
      brazeExports.openSession()
      // Enable logging?
    }
  }, [isLoaded, brazeExports])

  const initialize: UserContextValue['initialize'] = (
    apiKey: string,
    options: any,
  ) => {
    if (brazeExports?.initialize) {
      brazeExports.initialize(apiKey, options)
    }
  }
  const openSession: UserContextValue['openSession'] = () => {
    if (brazeExports?.openSession) {
      brazeExports.openSession()
    }
  }
  const automaticallyShowInAppMessages: UserContextValue['automaticallyShowInAppMessages'] =
    () => {
      if (brazeExports?.automaticallyShowInAppMessages) {
        brazeExports.automaticallyShowInAppMessages()
      }
    }
  const getUser: UserContextValue['getUser'] = () => {
    if (brazeExports?.getUser) {
      return brazeExports.getUser()
    }
    return null
  }
  const changeUser: UserContextValue['changeUser'] = (externalId: string) => {
    if (brazeExports?.changeUser) {
      brazeExports.changeUser(externalId)
    }
  }
  const logCustomEvent: UserContextValue['logCustomEvent'] = (
    eventName: string,
    eventData: any,
  ) => {
    if (brazeExports?.logCustomEvent) {
      brazeExports.logCustomEvent(eventName, eventData)
    }
  }

  const getGeoLocation: UserContextValue['getGeoLocation'] = async () => {
    const response = await fetch('/api/geolocation')
    const geoData: GeoLocationData = await response.json()
    return geoData
  }

  const value = {
    initialize,
    openSession,
    automaticallyShowInAppMessages,
    getUser,
    changeUser,
    isLoaded,
    logCustomEvent,
    getGeoLocation,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
